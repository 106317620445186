#about {
  z-index: 0;
  position: relative;
  color: $noir;

  p,
  h1,
  h2,
  h3,
  a {
    color: $noir;
    text-decoration: none;
  }
}

.col {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  .col1 {
    text-align: right;
    width:$col1*9;
  }
  .col2 {
    text-align: center;
    width:$col1*24;
    margin-top:60px;
    .intro {
      p {
        border:none;
        padding: 45px 0 45px;
        margin-bottom: 60px;
        max-width: $col1*12;
        margin-left: auto;
        margin-right:auto;
      }
    }
    .infos {
      background: #ffdacf;
      padding: 15px 0 45pxs;
    }
  }
  .col3 {
    text-align: left;
    width:$col1*9;
  }
  .intro {
    p {
    padding: 60px 0;
    border-bottom: 1px solid;
    }
  }

  .date {
    padding: 30px 0 60px;
    .item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 30px 0 0px 0;
      flex-direction: row-reverse;
      strong {
        width: 30%;
        letter-spacing: 5px;
        font-size: 12px;
      }
      p {
        width: 70%;
      }
    }
    &.date-pinta {
      .item {
        flex-direction: row;
      }
    }
  }
  .infos {
    padding: 30px 0 60px;
    p {
      padding: 10px 0 0;
      a {
        text-decoration: none;
      }
    }
  }
  .insta {
    padding: 30px 0 0;
    svg {
          width: 25px;
          height: 25px;
          display: inline-block;
          vertical-align: middle;
          fill: #010103;
    }
  }

}
.col .col1 svg {
  height: 27px;
  position: absolute;
  left: 0;
}
.col .col3 svg {
  height: 44px;
  position: absolute;
  right: 0;
}
.col4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid;
  background: #ffdacf;
  position: relative;
  border-bottom: 1px solid;
  margin-top: 60px;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
/* -- ABOUT ANIM --*/
body.about:not(.non-event) {
  .logo {
    width: 70%;
    left: 15%;
    background: #ffdacf;
    .helft-logo{
      top:0;
      transition: $transition;
      transition-delay: 1s;
    }
    .et-logo{
      top:5px;
      transition: $transition;
      transition-delay: 1s;
    }
    .pinta-logo {
      top:0;
      transition: $transition;
      transition-delay: 1s;
    }

  }
  .cote-gauche {
    .helft {
      a {
        top:35px;
        transition: $transition;
        transition-delay: 1s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 1s;
      }
    }
    .separateur {
      right:$col1*5;
      width:0;
      transition: width $transition 0s, right $transition 1s;
  
    }
    .separateur2 {
      width: 0;
      transition: width $transition 0s;
    }
    .separateur3 {
      width: 0;
      transition: width $transition 0s;
    }
  }
  .cote-droit {
    .pinta {
      a {
        top:35px;
        transition: $transition;
        transition-delay: 1s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 1s;
      }
    }

    .separateur {
      left:$col1*5;
      width:0;
      transition: width $transition 0s, left $transition 1s;
  
    }
    .separateur2 {
      width: 0;
      transition: width $transition 0s;
    }
    .separateur3 {
      width: 0;
      transition: width $transition 0s;
    }
  }


  .lien-about, .lien-index {

    span {
      position: relative;
      top:0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  }
  .lien-about {
    color:#999
  }
  .fond.fd-home {
    opacity:0;
    transition: $transition 3s;
  }
  .separator-middle {
    top: calc(100vh - 200px);
    transition: $transition 2s;
    opacity: 1;
  }
  .down {
    top: calc(100vh - 230px);
    opacity: 1;
    transition: $transition 1.6s;
  }
  .fond.fd-about {
    opacity:1;
    transition: $transition 0s;
  }
  .fond.fd-index {
    opacity:0;
    transition: $transition;
  }
  .barre-haut {
    background-color: #ffdacf;
  }   
  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }
  footer {
    transition: opacity $transition 0s, background-color $transition 1s;
  }
  #about .content {
    transition: opacity $transition 0s;
  }
}

