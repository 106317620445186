@charset 'UTF-8';
/* ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
►►► Styles/main
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ */

$rose-light: #F0ECE4;
$rose-dark: #EAE1D8;
$noir: #010103;
$gris: #818181;
$col1: 3.125vw;
$transition: 1s cubic-bezier(0.6, 0.0, 0.3, 1);
$transition2: 0.6s cubic-bezier(0.6, 0.0, 0.3, 1);

@font-face {
  font-family: 'FugueHead';
  src: url('fonts/FugueHead.woff2') format('woff2'),
    url('fonts/FugueHead.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Fugue';
  src: url('fonts/Fugue-Regular.woff2') format('woff2'),
    url('fonts/Fugue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


//  Helpers
//  ───────────────────────────────────
@import 'helpers/variables';
@import 'helpers/mixins';

//  Global
//  ───────────────────────────────────
@import 'global/commons';
@import 'global/locomotive-scroll';

//  Molecules
//  ───────────────────────────────────
@import 'molecules/header';
@import 'molecules/footer';

@import 'molecules/cookies';

@import 'molecules/home';
@import 'molecules/about';
@import 'molecules/index';

@import 'molecules/projets';

//  Atoms
//  ───────────────────────────────────
@import 'atoms/form';
@import 'atoms/alerts';
@import 'atoms/table';
@import 'atoms/modal';
@import 'atoms/buttons';
