//
//  T A B L E
//
//  ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

table{
  // width: 100%;
  border-collapse: collapse;
  margin-bottom: $vr*2;

  tbody tr{
    td{
      padding: 1rem 1.8rem;
      background-color: clr(primary);
      border: 3px solid clr(white);
    }

    .cell-link{
      padding: 0;
      background-color: clr(white);
      .button{
        padding: 1.4rem 2.6rem 1.2rem
      }
    }
  }

  thead{
    tr{
      th{
        padding: 1rem 1.8rem;
        font-family: $ff-myriadpro;
        color: clr(black);
        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
  }

  tfoot{
    tr{
      td{
        padding: $vr/2;
      }
    }
  }

  &.stripped{
    border: none;

    tbody tr{
      &:nth-of-type(odd){ background: clr(primary); }

      &:not(:last-of-type){ border: none; }
    }
  }

  &.responsive{
    @media #{$sm-only}{
      thead{
        display: none;
      }
      td,
      th{
        padding: 0;
      }
      tbody{

        tr{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;

          &:not(:last-of-type){
            padding-bottom: $vr;
            border-bottom: 1px solid clr(black);
          }

          &:not(:first-of-type){
            padding-top: $vr;
          }

          td{
            text-align: left;
            padding: $vr/2;
            border: 1px solid clr(white);

            &[data-header]{
              display: flex;
              flex-direction: column;
              width: 50%;
              flex: 0 0 50%;

              &::before{
                display: block;
                content: attr(data-header);
                text-align: left;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.35;
                color: clr(gray);
                margin-bottom: 0.4rem;
              }
            }
            &.cell-link{
              padding: 0;
              &, .button{
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

dl{
  dt{
    font-family: $ff-myriadpro;
    color: clr(black);
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    &:not(:first-of-type){
      margin-top: $vr;
    }
  }
  dd{
    font-size: 1.6rem;
    font-weight: 700;
  }
}
