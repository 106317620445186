//
//  C O M M O N S
//
//  ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

* {
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  overflow-x: hidden;
  margin-bottom: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: $noir;
  background: $rose-light;
  font-family: 'FugueHead', sans-serif;
  font-size: 14px;

  &.non-event {
    pointer-events: none;
  }
}

a {
  color: $noir;
}

section.content {
  min-height: calc(100vh - 520px);
}

main#folio-helft-index .content,
main#folio-helft-item .content {
  left: 0vw;
  position: relative;
  opacity: 0;
  transition: opacity $transition 2.5s;
}

main#home .content {
  top: 0vh;
  position: relative;
  opacity: 0;
  transition: opacity $transition 2.5s;

}

main#legal .content {
  top: 0vh;
  position: relative;
  opacity: 0;
  transition: opacity $transition 2.5s;
}

main#about .content {
  position: relative;
  opacity: 0;
  top: 0vh;
  transition: opacity $transition 2.5s;
}

main#folio-index .content {
  position: relative;
  opacity: 0;
  top: 0vh;
  transition: opacity $transition 2.5s;
}

main#folio-index-item .content {
  position: relative;
  opacity: 0;
  top: 0vh;
  transition: opacity $transition 2.5s;
}

main#folio-pinta-index .content {
  left: 0vw;
  position: relative;
  opacity: 0;
  transition: opacity $transition 2.5s;
}

main#folio-pinta-item .content {
  left: 0vw;
  position: relative;
  opacity: 0;
  transition: opacity $transition 2.5s;
}

main#index .content,
main#press .content,
main#partners .content {
  left: 0vw;
  position: relative;
  opacity: 0;
  transition: opacity $transition 2.5s;
}

body:not(.folio-helft-index) main#folio-helft-index .content,
body:not(.folio-helft-item) main#folio-helft-item .content,
body:not(.home) main#home .content,
body:not(.folio-item) main#folio-index-item .content,
body:not(.legal) main#legal .content,
body:not(.about) main#about .content,
body:not(.folio-pinta-index) main#folio-pinta-index .content,
body:not(.folio-pinta-item) main#folio-pinta-item .content,
body:not(.folio-index) main#index .content,
body:not(.press) main#press .content,
body:not(.partners) main#partners .content {
  transition: opacity $transition 0s;
  opacity: 0 !important;
}

body.non-event.folio-helft-index main#folio-helft-index .content,
body.non-event.folio-helft-item main#folio-helft-item .content,
body.non-event.home main#home .content,
body.non-event.folio-item main#folio-index-item .content,
body.non-event.legal main#legal .content,
body.non-event.about main#about .content,
body.non-event.folio-pinta-index main#folio-pinta-index .content,
body.non-event.folio-pinta-item main#folio-pinta-item .content,
body.non-event.folio-index .folio-index main#index .content,
body.non-event.press main#press .content,
body.non-event.partners main#partners .content {
  transition: none;
  opacity: 0 !important;
}

.credits,
.cookies {
  display: none;
}

main {
  padding-top: 125px;
  padding-left: $col1*4;
  padding-right: $col1*4;
}

main#home,
main#folio-helft-index,
main#folio-pinta-index,
main#folio-helft-item,
main#folio-pinta-item,
main#folio-index-item {
  padding-left: $col1*3;
  padding-right: $col1*3;
}

section {
  &:not(.no-margin) {
    margin-bottom: 3rem;

    @media #{$md} {
      margin-bottom: 3rem;
    }
  }
}

hr {
  border: none;
  border-bottom: 1px solid clr(black);
  width: 100%;
  margin: 2rem auto;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  transition: opacity $anim;
  margin: auto;

  &.lazyload {
    opacity: 0;
  }

  &.lazyloaded {
    opacity: 1;
  }
}

iframe {
  max-width: 100%;
}

h3,
p {
  font-family: 'Fugue', sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 16px;
  letter-spacing: 5px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
span,
blockquote,
label,
input,
address,
select,
button,
dt,
dd,
th,
td {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: blackscale;
}

::-moz-selection {
  background: rgba(clr(black), 0.8);
  color: clr(white);
}

::selection {
  background: rgba(clr(black), 0.8);
  color: clr(white);
}

input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h3,
.h3 {
  font-size: 12px;
  letter-spacing: 3px;
  font-weight: 400;
  text-transform: none;
  margin: 0;
}

.h3 {
  color: #818181;
}

.title {
  overflow: hidden;
  transition: $transition;
}

.title h1 {
  position: relative;
  top: 40px;
  color: $noir;
  transition: $transition 1s;
  text-align: center;
}

h2,
.h2 {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 400;
  font-size: 16px;
}

a {
  .voirp {
    width: 80px;
    height: 80px;
    margin: 30px auto;
    border: 1px solid;
    border-radius: 50%;
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    line-height: 1.2;
    align-items: center;
    justify-content: center;

    span {
      top: 0 !important;
    }
  }

  &:hover {
    .voirp {
      span {
        top: 0 !important;
      }
    }
  }
}

.hide {
  opacity: 1;
  position: relative;
  transition: $transition;

  &.is-inview {
    opacity: 1;
    top: 0
  }
}
a:not(.archive-items) {
  display: block;

  span {
    transition: $transition2;
    transform: rotate(0deg);
    position: relative;
    top: 0;
    text-decoration: none;
    z-index: 1;
    line-height: 1.2;
  }

  .hovered {
    display: none;
  }
}
@media screen and (min-width:960px) {
  a:not(.archive-items) {
    display: block;

    span {
      transition: $transition2;
      transform: rotate(0deg);
      position: relative;
      top: 0;
      text-decoration: none;
      z-index: 1;
      line-height: 1.2;
    }

    .hovered {
      position: absolute !important;
      top: 20px !important;
      left: 50% !important;
      transition: $transition2;
      transform: translate(-50%);
      width: 100%;
      text-decoration: none;
      display: block;
    }

    &:hover {
      span {
        top: -15px !important;

      }

      .hovered {
        transform: translate(-50%);
        position: absolute !important;
        top: 0px !important;
      }
    }
  }
}
.img-wrap {
  img {
    opacity: 1;
  }

  position: relative;
  transform-origin: top;
  transition: $transition2;

}
.is-inview .img-wrap img {
  opacity: 1;
}

.c-scrollbar_thumb {
  width: 2px !important;
  opacity: 1 !important;
  right: 2px !important;
  background: #FFF !important;
  mix-blend-mode: difference;
  z-index: 99999;
}

.hided {
  opacity: 0;
  pointer-events: none;
}

.classic-content {
  max-width: 600px;
  margin: auto;
  padding: 100px 0;

  h1 {
    margin: 0 0 60px;
  }

  h2 {
    margin: 15px 0 15px;
    text-transform: uppercase;
  }

  h3 {
    margin: 15px 0 15px;
    text-transform: uppercase;
  }

  ul {
    padding: 30px;
  }
}
main#error img {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

main#error {
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:0;
}

main#error .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

main#error .content h1 {
  color: #FFF;
  font-size: 30px;
  margin: 10px;
}

main#error .content a {
  color: #FFF;
  text-decoration: none;
  border-bottom: 1px solid;
  display: inline-block;
}
body.legal:not(.non-event) {
  .logo {
    width: 570px;
    left: calc(50% - 285px);
    background: $rose-dark;

    .helft-logo {
      top: 0;
      transition: $transition;
      transition-delay: 1s;
    }

    .et-logo {
      top: 5px;
      transition: $transition;
      transition-delay: 1.1s;
    }

    .pinta-logo {
      top: 0;
      transition: $transition;
      transition-delay: 1.2s;
    }

  }

  .cote-gauche {
    .helft {
      a {
        top: 0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }

    .et {
      a {
        top: 40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }

    .separateur {
      right: $col1*5;
      width: $col1*7;
      transition: width $transition 1s, right $transition 1s;

    }

    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }

    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }

  .cote-droit {
    .pinta {
      a {
        top: 0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }

    .et {
      a {
        top: 40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }

    .separateur {
      left: $col1*5;
      width: $col1*7;
      transition: width $transition 1s, left $transition 1s;

    }

    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }

    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }

  .title h1 {
    top: 0;
    transition: top $transition 1.6s;
  }

  .lien-about,
  .lien-index {
    span {
      position: relative;
      top: 0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  }

  .fond.fd-home {
    opacity: 1;
    transition: $transition 0s;
  }

  .vertical-separateur {
    top: calc(100vh - 270px);
    opacity: 1;
    transition: $transition 2.6s;
  }

  .down {
    top: calc(100vh - 230px);
    opacity: 0;
    transition: $transition 2.6s;
  }

  .fond.fd-about {
    opacity: 0;
    transition: $transition;
  }

  .fond.fd-index {
    opacity: 0;
    transition: $transition;
  }

  .barre-haut {
    background-color: $rose-dark;
  }

  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }

  footer {
    opacity: 1;
    transition: opacity $transition 0s, background-color $transition 1s;
  }

  #home .content {
    transition: opacity $transition 0s;
  }
}

@media screen and (max-width:960px) {

  main#home,
  main#folio-helft-index,
  main#folio-pinta-index,
  main#folio-helft-item,
  main#folio-pinta-item,
  main#folio-index-item {
    padding-left: $col1*2;
    padding-right: $col1*2;
  }

  .archive {
    padding: 0px 0px 0;
    margin: 0 0;
  }

  .loop-projets.home .bloc-projet:nth-child(3),
  .loop-projets.home .bloc-projet:nth-child(3n+6),
  .loop-projets.pinta-loop .bloc-projet:nth-child(2),
  .loop-projets.pinta-loop .bloc-projet:nth-child(2n+6),
  .loop-projets.pinta-loop .bloc-projet:nth-child(3),
  .loop-projets.pinta-loop .bloc-projet:nth-child(3n+6),
  .loop-projets.pinta-loop .bloc-projet:nth-child(5),
  .loop-projets.pinta-loop .bloc-projet:nth-child(5n+6),
  .loop-projets.pinta-loop .bloc-projet:nth-child(4),
  .loop-projets.pinta-loop .bloc-projet:nth-child(4n+6),
  .loop-projets.helft-loop .bloc-projet:nth-child(1),
  .loop-projets.helft-loop .bloc-projet:nth-child(1n+6),
  .loop-projets.helft-loop .bloc-projet:nth-child(2),
  .loop-projets.helft-loop .bloc-projet:nth-child(2n+6),
  .loop-projets.helft-loop .bloc-projet:nth-child(3),
  .loop-projets.helft-loop .bloc-projet:nth-child(3n+6),
  .loop-projets.helft-loop .bloc-projet:nth-child(4),
  .loop-projets.helft-loop .bloc-projet:nth-child(4n+6),
  .loop-projets.home .bloc-projet:nth-child(2),
  .loop-projets.home .bloc-projet:nth-child(2n+6),
  .loop-projets .bloc-projet {
    padding: $col1 0;
  }

  .lien-index {
    right: calc(6.25vw - 35px);
  }

  .lien-about {
    left: calc(6.25vw - 35px);
  }

  header .barre-haut .cote-droit .helft,
  header .barre-haut .cote-droit .pinta,
  header .barre-haut .cote-gauche .helft,
  header .barre-haut .cote-gauche .pinta {
    width: $col1*3;
  }

  header .barre-haut .cote-gauche .separateur2 {
    right: $col1*11;
  }

  header .barre-haut .cote-droit .separateur2 {
    left: $col1*11;
  }

  header .barre-haut .cote-gauche .separateur {
    right: $col1*1 !important;
  }

  body.home:not(.non-event) .cote-gauche .separateur,
  body.press:not(.non-event) .cote-gauche .separateur,
  body.index:not(.non-event) .cote-gauche .separateur,
  body.partners:not(.non-event) .cote-gauhe .separateur {
    right: $col1*4 !important;
  }

  header .barre-haut .cote-droit .separateur {
    left: $col1*1 !important;
  }

  body.home:not(.non-event) .cote-droit .separateur,
  body.press:not(.non-event) .cote-droit .separateur,
  body.index:not(.non-event) .cote-droit .separateur,
  body.partners:not(.non-event) .cote-droit .separateur {
    left: $col1*4 !important;
  }

  header .barre-haut .logo {
    transform: translate(0, 0px) scale(0.8);
  }

  main {
    padding-top: 100px;
  }

  h2,
  .h2 {
    font-size: 14px;
    letter-spacing: 3px;
  }

  .folio-pinta-index .loop-projets {
    align-items: flex-start;
  }

  .suite {
    width: $col1*24;
  }

  .subnav {
    top: 60px;
  }

  main#index,
  main#press,
  main#partners {
    padding-top: 180px;
    padding-left: $col1*2;
    padding-right: $col1*2;
  }

  .archive h3 {
    width: $col1*13;
  }

  .archive p.lieu {
    width: $col1*11;
  }

  .first-bloc {
    padding-top: 0;
  }
}

@media screen and (max-width:680px) {

  .loop-projets.home .bloc-projet:nth-child(3),
  .loop-projets.home .bloc-projet:nth-child(3n+6),
  .loop-projets.pinta-loop .bloc-projet:nth-child(2),
  .loop-projets.pinta-loop .bloc-projet:nth-child(2n+6),
  .loop-projets.pinta-loop .bloc-projet:nth-child(3),
  .loop-projets.pinta-loop .bloc-projet:nth-child(3n+6),
  .loop-projets.pinta-loop .bloc-projet:nth-child(5),
  .loop-projets.pinta-loop .bloc-projet:nth-child(5n+6),
  .loop-projets.pinta-loop .bloc-projet:nth-child(4),
  .loop-projets.pinta-loop .bloc-projet:nth-child(4n+6),
  .loop-projets.helft-loop .bloc-projet:nth-child(1),
  .loop-projets.helft-loop .bloc-projet:nth-child(1n+6),
  .loop-projets.helft-loop .bloc-projet:nth-child(2),
  .loop-projets.helft-loop .bloc-projet:nth-child(2n+6),
  .loop-projets.helft-loop .bloc-projet:nth-child(3),
  .loop-projets.helft-loop .bloc-projet:nth-child(3n+6),
  .loop-projets.helft-loop .bloc-projet:nth-child(4),
  .loop-projets.helft-loop .bloc-projet:nth-child(4n+6),
  .loop-projets.home .bloc-projet:nth-child(2),
  .loop-projets.home .bloc-projet:nth-child(2n+6),
  .loop-projets .bloc-projet {
    flex: 0 0 100%;
    width: 100%;
  }

  .loop-projets .bloc-projet .caption {
    background: #eae1d8;
  }

  main {
    padding-top: 40px;
  }

  .home .loop-projets.home .bloc-projet:nth-child(1) .caption {
    background: transparent;
    border-bottom: 0;
  }

  .lien-index {
    transform: none;
    width: 50vw;
    right: 0;
    background: #eae1d8;
    z-index: 999;
    bottom: 0;
    padding: 10px;
    text-align: center;
    border-top: 1px solid #000;
  }

  .lien-about {
    transform: none;
    width: 50vw;
    left: 0;
    background: #eae1d8;
    padding: 10px;
    text-align: center;
    bottom: 0;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
  }


  .lien-index .hovered {
    display: none;
  }

  .lien-about .hovered {
    display: none;
  }

  header .barre-haut .cote-droit .helft,
  header .barre-haut .cote-droit .pinta,
  header .barre-haut .cote-gauche .helft,
  header .barre-haut .cote-gauche .pinta {
    width: 10px;
    margin: 10px 15px 10px 15px;
  }

  header .barre-haut .cote-droit .et,
  header .barre-haut .cote-gauche .et {
    width: 18px;
    height: 18px;
    padding-top: 2px;
    margin: 10px 15px 10px 15px;
  }

  header .barre-haut .logo {
    transform: translate(0, -55px) scale(0.4);
  }

  header .cote-gauche {
    width: 50vw !important;
  }

  header .cote-droit {
    width: 50vw !important;
  }

  header .barre-haut {
    padding: 15px 0;
    background: transparent;
  }

  .title {
    margin-bottom: 1.5rem !important;
    margin-top: 1rem;
  }

  .down {
    display: none !important;
  }

  header .barre-haut .cote-gauche .separateur {
    right: -$col1 !important;
  }

  header .barre-haut .cote-droit .separateur {
    left: -$col1 !important;
  }

  #folio-helft-index .loop-projets .bloc-projet .caption,
  #folio-pinta-index .loop-projets .bloc-projet .caption {
    background: #F0ECE4;
  }

  body.about:not(.non-event) .logo {
    width: 200%;
    left: -50%;
  }

  .col .col2 .intro p {
    max-width: 100%;
  }

  .col4 {
    flex-wrap: wrap;
    margin: 0px;
    border-top: 0px solid;
  }

  main#about {
    padding-left: $col1*2;
    padding-right: $col1*2;
  }

  .col .infos {
    width: 100%;
  }

  .col .col2 {
    width: 100%;
  }

  .col .infos {
    width: 100%;
  }

  .intro {
    width: 100%;
  }

  .col .col2 {
    width: 100%;
    margin-top: 30px;
  }

  .col .col1 {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid;
    border-top: 1px solid;
    padding-top: 60px;
  }

  .col .col3 {
    width: 100%;
    text-align: left;
    margin-top: 0;
    padding-top: 50px;
    border-top: 1px solid;
    background: #fddacf;
    position: relative;
    border-bottom: 1px solid;
    border-top: 0;
  }

  .col .date {
    background: #fddacf;
  }

  .col .col3 svg {
    left: 0;
    z-index: 1;
  }

  #about p,
  #about h1,
  #about h2,
  #about h3,
  #about a {
    background: #fddacf;
    position: relative;
  }

  .col .col1 svg {
    z-index: 1;
  }

  .col .date .item {
    flex-direction: row;
  }

  #about .lien-index {
    background: red;
  }

  .about .lien-about,
  .about .lien-index {
    background: #fddacf;
  }

  footer#footer {
    opacity: 1;
    padding: 0 30px 90px;
  }

  .subnav .subnav-line {
    width: auto;
    padding: 0 15px;
  }

  .subnav .subnav-line a {
    /* font-size: 12px; */
  }

  .archive .item .flexed {
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid;
  }

  .archive .item .flexed>* {
    width: 100%;
    position: relative;
  }

  .archive span.icon svg {
    position: absolute;
    top: -20px;
    right: 0;
  }

  .archive span.date {}

  .archive .item:first-child {
    padding-top: 0;
    padding-bottom: 0;
  }

  .archive .item {
    padding: 0;
  }

  .archive .item:last-child {
    border: navajowhite;
    padding-bottom: 0;
  }

  .subnav {}

  main#index,
  main#press,
  main#partners {
    padding-top: 130px;
  }

  .subnav .subnav-line:nth-child(2) {
    order: -1;
  }

  .subnav .subnav-line {
    order: 1;
  }

  .subnav .sep-active {
    display: none;
  }

  .folio-index .lien-about,
  .folio-index .lien-index,
  .press .lien-about,
  .press .lien-index,
  .partners .lien-about,
  .partners .lien-index {
    background: #c5d5c7;
  }

  .first-bloc {
    height: 400px;
    width: 100%;
    order: -1;
  }

  .bloc-infos {
    width: 100%;
    background: #f0ece4;
    border-bottom: 1px solid;
  }

  #folio-pinta-item .suite {
    width: 100%;
    margin-top: 0;
  }

  .attr.desc {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid;
    margin-bottom: 30px;
    background: #f0ece4;
    position: relative;
    padding-top: 30px;
  }

  .suite img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
  .suite {
    width: 100%;
    margin: 0;
  }
  .suite img {
    padding: 0 0 60px !important;
}
.folio-helft-item .lien-about,
.folio-helft-index .lien-about,
.folio-helft-index .lien-index,
.folio-helft-item .lien-index,
.folio-pinta-item .lien-about,
.folio-pinta-index .lien-about,
.folio-pinta-index .lien-index,
.folio-pinta-item .lien-index {
  background: #f0ece4;
}
.lien-about, .lien-index {
  opacity: 0;
  padding: 10px 0;
}
body:not(.non-event) .lien-about, body:not(.non-event) .lien-index {
  opacity: 1;
}
.lien-about span, .lien-index span {
  opacity: 1;
  font-size: 10px;
  letter-spacing: 2px;
}
.subnav .subnav-line a {
  font-size: 12px;
}
body.folio-pinta-item:not(.non-event) a:not(.archive-items).lien-retour-p {
    transform: none;
    bottom: auto;
    top: 24px;
    right: calc(6.25vw);
    left: auto;
}
body.folio-helft-item:not(.non-event) a:not(.archive-items).lien-retour {
  transform: none;
  bottom: auto;
  top: 24px;
  left: calc(6.25vw);
  right: auto;
}
body.folio-item:not(.non-event) a:not(.archive-items).lien-retour-i {
  transform: none;
  bottom: auto;
  top: 54px;
  left: 22px;
  right: 22px;
  text-align: center;
}

body.folio-helft-item:not(.non-event) a:not(.archive-items).lien-retour span,
body.folio-pinta-item:not(.non-event) a:not(.archive-items).lien-retour-p span,
body.folio-item:not(.non-event) a:not(.archive-items).lien-retour-i span {
  font-size: 10px;
}
.separator-middle {
  display: none;
}
#folio-index-item .bloc-title {
  width: 100%;
  margin: 0;
}

#folio-index-item .suite .bloc-infos {
  width: 100%;
  margin: 0;
  background: #eae1d8;
}

#folio-index-item .suite .attr.desc {
  width: 100%;
  background: #eae1d8;
}

#folio-index-item .suite .infos-content {
  padding-bottom: 0;
  margin: 0;
}
.next .next-item:nth-child(1) a {flex-direction: column;align-items: center;}

.next .next-item:nth-child(2) a {
    flex-direction: column;
    align-items: center;
}

.next .next-item a .mini-caption {
    width: 100% !important;
    text-align: center !important;
    margin: 15px 0 10px;
    padding: 0 !important;
}

.next .next-item a .mini-cover {
    width: 100% !important;
    height: auto !important;
}

.next .next-item {
    width: 100%;
}

.next {
    padding: 30px 0 0;
}
.home .loop-projets.home .bloc-projet:nth-child(1) {
  margin-bottom:30px;
}
}
