//
//  C O O K I E S
//
//  ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

.credits,
.cookies {
  display: none;
}
.m-cookie{
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem 0;
  z-index: 100;
  &.show{
    display: block;
  }
  @media #{$lg}{
    bottom: $vr;
    left: $vr;
    max-width: 30rem;
    padding: 0;
  }
}

