.loop-projets {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  &.home {
    .bloc-projet {
      &:nth-child(2),
      &:nth-child(2n+6) {
        padding: $col1 $col1 $col1 0;
      }
      &:nth-child(3),
      &:nth-child(3n+6) {
        padding:$col1*3;
      }
    }
  }
  &.helft-loop {
    .bloc-projet {
      &:nth-child(1),
      &:nth-child(1n+6) {
        padding: $col1*3;
      }

      &:nth-child(2),
      &:nth-child(2n+6) {
        padding: $col1 0 $col1 $col1;
      }

      &:nth-child(3),
      &:nth-child(3n+6) {
        padding: 0 $col1*2 $col1 0;
      }

      &:nth-child(4),
      &:nth-child(4n+6) {
        padding: $col1*2;
      }

      &:nth-child(5),
      &:nth-child(5n+6) {
        padding: 0 $col1 0 $col1;
      }

      &:nth-child(6),
      &:nth-child(6n+6) {
        padding: $col1*4 0 $col1 $col1*3;
      }
    }
  }
  &.pinta-loop {
    .bloc-projet {
      &:nth-child(2),
      &:nth-child(2n+6) {
        padding: $col1*3;
      }
      &:nth-child(1),
      &:nth-child(1n+6) {
        padding: $col1 $col1 $col1 $col1;
      }

      &:nth-child(4),
      &:nth-child(4n+6) {
        padding: 0 $col1*2 $col1 $col1;
      }

      &:nth-child(3),
      &:nth-child(3n+6) {
        padding: $col1*2;
      }

      &:nth-child(6),
      &:nth-child(6n+6) {
        padding: 0 $col1 0 $col1;
      }

      &:nth-child(5),
      &:nth-child(5n+6) {
        padding: $col1*4 $col1*3 $col1 $col1*3;
      }
    }
  }
  .bloc-projet {
    flex: 0 0 50%;
    padding:$col1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    transition: $transition;
    position: relative;

    a {
      text-decoration: none;
      &:hover {
        .cover img,
        .big-cover {
          transform: scale(0.99);
        }
      }
    }

    .cover {
      position: relative;
      overflow: hidden;
      img {
        transition: $transition;
      }
    }
    .voirp {
      position: absolute;
      bottom:-150px;
      left:0;
      right: 0;
      transition: $transition;
      color: $rose-light;
    }

    .caption {
      padding: 30px 0;
    }
    h3 {
      color:#818181;
    }
  }
}
.big-cover{
  transition: $transition;
}
a {
  &:hover {
    .big-cover {
      transform: scale(0.97);
    }
  }
}
#fixie-next {
  margin-bottom: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.bloc-infos {
  width: $col1*8;
  padding-left: 0;
  z-index: 1;
  position: relative;
  padding:50px 0;
  transition: padding 0.6s cubic-bezier(0.6, 0, 0.3, 1) !important;
  &.scrolled {
      transition: padding 0.6s cubic-bezier(0.6, 0, 0.3, 1) !important;
      padding: 60px 0 !important;
  }
  .attr {
      padding: 15px 0 0;
      font-size: 14px;
      text-transform: initial;
      font-family: 'Fugue';
    span {
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size:12px;
      display: block;
    }
    p {
      display: inline-block;
    }
    
  }
  .h3 {
    color:#818181;
    padding-bottom: 15px;
  }
}
.first-bloc {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
  margin-left: auto;
  padding-top: 52px;
  height: calc(100vh - 200px);

  img {
    width: 100%;
    height:100%;
    object-fit: cover;
    object-position: center;
  }
}

.suite {
  width: $col1*18;
  margin-left:auto;
  margin-top: 120px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-start;
  img {
    padding: 0 0 60px 60px;
    max-height: 650px;
    object-fit: contain;
    margin-left:auto;
    margin-right:0;
  }
}
.sep-next {
  border-top: 1px solid $noir;
  background: $rose-light;
  max-width: $col1*12;
  margin: auto;
}
.folio-item {
  .next {
    background: $rose-dark;
  }
}
.next {
  background:$rose-light;
  padding: 40px $col1;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  .h4 {
    margin: 30px auto 0;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    border-bottom: 1px solid;
  }
  .next-item {
    padding: 0 $col1/2;
    width:50%;
    &:nth-child(1){
      a {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        text-decoration: none;
        .mini-cover {
          width:120px;
          height:120px;
          img {
            width:100%;
            height:100%;
            object-fit: cover;

          }
        }
        .mini-caption {
          padding:0 $col1 0 0;
          text-align: right;
          width:calc(100% - 120px);
        }
      }
    }
    &:nth-child(2) {
      a {
        display: flex;
        align-items: center;
        flex-direction: row;
        text-decoration: none;
        .mini-cover {
          width: 120px;
          height: 120px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .mini-caption {
          padding: 0 0 0 $col1;
          text-align: left;
          width:calc(100% - 120px);
        }
      }
    }
  }

}

#folio-pinta-item {
  #fixie-next {
      flex-direction: row-reverse;
  }
  .first-bloc {
    margin-left: 0;
    margin-right: auto;
  }
  .suite {
    margin-left: 0;
    margin-right: auto;
    justify-content:flex-start;
    img {
      margin-left: 0;
      margin-right: auto;
      padding-left: 0;
      padding-right: 60px;
    }
  }
}
.attr.desc {
  padding-bottom: 100px;
  width: 50%;
}
.attr .plus {
  display: none;
}

.tab {
  width: 100%;
  overflow: hidden;
  input {
    display: none;
  }
  .tab-label {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size:12px;

    // &::after {
    //   content: "\2DF";
    //   margin: 0;
    //   font-size: 40px;
    //   text-align: center;
    //   transition: all .35s;
    //   transform: rotate(45deg);
    //   line-height: 0.75;
    //   height: 20px;
    //   left: 5px;
    //   position: relative;
    //   transform-origin: center;
    // }
    svg {
      width: 12px;
      height: 12px;
      top: 2px;
      position: relative;
      right: -5px;
    }
  }

  .tab-content {
    max-height: 0;
    padding: 0;
    transition: all .35s;
    text-transform: none;
  }

  .tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;

  }
}

// :checked
input:checked {
  +.tab-label {

    // &::after {
    //   transform: rotate(90deg);
    // }
    svg {
      transform: rotate(-45deg);
    }
  }

  ~.tab-content {
    max-height: 100vh;
    padding: 1em 0;
  }
}

#folio-helft-item {
  .attr.desc {
    margin-left:20%;
    margin-right: 0;
  }
}

#folio-index-item {
  .bloc-title {
    width: 40%;
    margin-right: 60%;
    position: relative;
    padding: 0;
    display: block;
    margin: -30px 60% 40px 0px;
  }
  .first-bloc {
    width:100%;
    height: calc(100vh - 265px);
  }
  .suite {
    width:100%;
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding-left: 0rem;
    .infos-content {
      display: flex;
      padding-bottom: 6rem;
      align-items: flex-start;
    }
    .bloc-infos {
      width:40%;
      margin-right: 5%;
      position: relative;
      padding:0;
      margin-top: -15px;
      .attr {
        p {
          display: inline-block;
        }
      }
    }
    .attr.desc {
      width:43%;
      margin-left:auto;
      margin-right: 0;
    }
  }
}

main#folio-pinta-item,
main#folio-helft-item,
main#folio-index-item {
  padding-top: 60px;
}

/* -- PINTA ANIM -- */
body.folio-pinta-index:not(.non-event) {
  .logo {
    width: 300px;
    left: calc(50% - 150px);
    background: $rose-light;
    .helft-logo{
      top:100px;
      transition: $transition;
      transition-delay: 0s;
      left:calc(50% - 105px);
    }
    .et-logo{
      top:100px;
      transition: $transition;
      transition-delay: 0s;
    }
    .pinta-logo {
      top:0;
      right:calc(50% - 105px);
      transition: $transition;
      transition-delay: 1s;
    }
  }

  .cote-gauche {
    .helft {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }
    }
    .et {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }
    }
    .separateur {
      right:$col1*2;
      width:$col1*9;
      transition: width $transition 1s, right $transition 1s;
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 0.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 0.6s;
    }
  }
  .cote-droit {
    .pinta {
      a {
        top:15px;
        transition: $transition;
        transition-delay: 0s;
      }

    }
    .et {
      a{
        top:40px;
        transition: $transition;
        transition-delay: 0s;
      }

    }
    .separateur {
      left:$col1*2;
      width:0;
      transition: width $transition 1s, left $transition 1.6s;
    }
    .separateur2 {
      width: 0;
      transition: width $transition 0.6s;
    }
    .separateur3 {
      width: 0;
      transition: width $transition 0.6s;

    }
  }
  .barre-haut {
    background-color: $rose-light;
  }
  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }
  footer {
    opacity: 1;
    transition: opacity $transition 2s, background-color $transition 2s;
  }
  .lien-about, .lien-index {
    span {
      position: relative;
      top:0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  }
  .fond.fd-home {
    opacity:0;
    transition: $transition 0s;
  }
  .separator-middle {
    top: calc(100vh - 200px);
    transition: $transition 3s;
    opacity: 1;
  }
  .down {
    top: calc(100vh - 230px);
    opacity: 1;
    transition: $transition 2.6s;
  }
  .fond.fd-about {
    opacity:0;
    transition: $transition;
  }
  .fond.fd-index {
    opacity:0;
    transition: $transition;
  }
  main#folio-pinta-index .content {
    transition: opacity $transition 0s;
  }
}
body.folio-pinta-item:not(.non-event) {
  .logo {
    width: 300px;
    left: calc(50% - 150px);
    background: $rose-light;
    .helft-logo{
      top:100px;
      transition: $transition;
      transition-delay: 0s;
      left:calc(50% - 105px);
    }
    .et-logo{
      top:100px;
      transition: $transition;
      transition-delay: 0s;
    }
    .pinta-logo {
      top:0;
      right:calc(50% - 105px);
      transition: $transition;
      transition-delay: 1s;
    }
  }

  .cote-gauche {
    .helft {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }
    }
    .et {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }
    }
    .separateur {
      right:$col1*2;
      width:$col1*9;
      transition: width $transition 1s, right $transition 1s;
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 0.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 0.6s;
    }
  }
  .cote-droit {
    .pinta {
      a {
        top:15px;
        transition: $transition;
        transition-delay: 0s;
      }

    }
    .et {
      a{
        top:40px;
        transition: $transition;
        transition-delay: 0s;
      }

    }
    .separateur {
      left:$col1*2;
      width:0;
      transition: width $transition 1s, left $transition 1.6s;
    }
    .separateur2 {
      width: 0;
      transition: width $transition 0.6s;
    }
    .separateur3 {
      width: 0;
      transition: width $transition 0.6s;

    }
  }
  .barre-haut {
    background-color: $rose-light;
  }
  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }
  footer {
    opacity: 1;
    transition: opacity $transition 2s, background-color $transition 2s;
  }
  .lien-about, .lien-index {
    span {
      position: relative;
      top:0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  }
  .fond.fd-home {
    opacity:0;
    transition: $transition 0s;
  }
  .vertical-separateur {
    top: calc(100vh - 270px);
    transition: $transition 3s;
    opacity: 1;
  }
  .down {
    top: calc(100vh - 160px);
    opacity: 1;
    transition: $transition 2.6s;
  }
  .fond.fd-about {
    opacity:0;
    transition: $transition;
  }
  .fond.fd-index {
    opacity:0;
    transition: $transition;
  }
  main#folio-pinta-index .content {
    transition: opacity $transition 0s;
  }
}

body.folio-pinta-item:not(.non-event) a:not(.archive-items).lien-retour-p {
  pointer-events: all;
  bottom: auto;
  z-index: 9999;
  opacity: 1;
  transition: $transition;
  transition-delay: 1.9s;
}
body.folio-helft-item:not(.non-event) a:not(.archive-items).lien-retour {
  pointer-events: all;
  bottom: auto;
  z-index: 9999;
  opacity: 1;
  transition: opacity $transition;
  transition-delay: 1.9s;
}
body.folio-item:not(.non-event) a:not(.archive-items).lien-retour-i {
  pointer-events: all;
  bottom: auto;
  z-index: 9999;
  opacity: 1;
  transition: opacity $transition;
  transition-delay: 1.9s;
}

body.folio-helft-item:not(.non-event) a:not(.archive-items).lien-retour span,
body.folio-pinta-item:not(.non-event) a:not(.archive-items).lien-retour-p span,
body.folio-item:not(.non-event) a:not(.archive-items).lien-retour-i span {
  top: 0;
  transition: $transition;
  transition-delay: 1.9s;
}
#folio-index-item {
  .suite {
    img {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

/* -- HELFT ANIM -- */
body.folio-helft-index:not(.non-event) {
  .logo {
    width: 300px;
    left: calc(50% - 150px);
    background: $rose-light;
    .helft-logo{
      top:0px;
      left:calc(50% - 105px);
      transition: $transition;
      transition-delay: 1s;
    }
    .et-logo{
      top:100px;
      transition: $transition;
      transition-delay: 0s;
    }
    .pinta-logo {
      top:100px;
      right:calc(50% - 105px);
      transition: top $transition 0s, right $transition 2s;
    }
  }

  .cote-gauche {
    .helft {
      a {
        top:15px;
        transition: $transition;
        transition-delay: 0s;
      }
    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0s;
      }
    }
    .separateur {
      right:$col1*2;
      width:0;
      transition: width $transition 1s, right $transition 1.6s;
    }
    .separateur2 {
      width: 0;
      transition: width $transition 0.6s;

    }
    .separateur3 {
      width: 0;
      transition: width $transition 0.6s;

    }
  }
  .cote-droit {
    .pinta {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a{
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }
    }
    .separateur {
      left:$col1*2;
      width:$col1*9;
      transition: width $transition 1s, left $transition 1s;

    }
    .separateur2 {
      width: $col1;
      transition: width $transition 0.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 0.6s;

    }
  }
  .barre-haut {
    background-color: $rose-light;
  }
  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }
  footer {
    opacity: 1;
    transition: opacity $transition 2s, background-color $transition 2s;
  }
  .lien-about, .lien-index {
    span {
      position: relative;
      top:0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  }
  .fond.fd-home {
    opacity:0;
    transition: $transition 0s;
  }
  .separator-middle {
    top: calc(100vh - 200px);
    transition: $transition 3s;
    opacity: 1;
  }
  .down {
    top: calc(100vh - 230px);
    opacity: 1;
    transition: $transition 2.6s;
  }
  .fond.fd-about {
    opacity:0;
    transition: $transition;
  }
  .fond.fd-index {
    opacity:0;
    transition: $transition;
  }
  main#folio-helft-index .content {
    transition: opacity $transition 0s;
  }
}


body.folio-helft-item:not(.non-event) {
  .logo {
    width: 300px;
    left: calc(50% - 150px);
    background: $rose-light;
    .helft-logo{
      top:0px;
      left:calc(50% - 105px);
      transition: $transition;
      transition-delay: 1s;
    }
    .et-logo{
      top:100px;
      transition: $transition;
      transition-delay: 0s;
    }
    .pinta-logo {
      top:100px;
      right:calc(50% - 105px);
      transition: top $transition 0s, right $transition 2s;
    }
  }

  .cote-gauche {
    .helft {
      a {
        top:15px;
        transition: $transition;
        transition-delay: 0s;
      }
    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0s;
      }
    }
    .separateur {
      right:$col1*2;
      width:0;
      transition: width $transition 1s, right $transition 1.6s;
    }
    .separateur2 {
      width: 0;
      transition: width $transition 0.6s;

    }
    .separateur3 {
      width: 0;
      transition: width $transition 0.6s;

    }
  }
  .cote-droit {
    .pinta {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a{
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }
    }
    .separateur {
      left:$col1*2;
      width:$col1*9;
      transition: width $transition 1s, left $transition 1s;

    }
    .separateur2 {
      width: $col1;
      transition: width $transition 0.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 0.6s;

    }
  }
  .barre-haut {
    background-color: $rose-light;
  }
  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }
  footer {
    opacity: 1;
    transition: opacity $transition 2s, background-color $transition 2s;
  }
  .lien-about, .lien-index {
    span {
      position: relative;
      top:0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  }
  .fond.fd-home {
    opacity:0;
    transition: $transition 0s;
  }
  .vertical-separateur {
    top: calc(100vh - 270px);
    transition: $transition 3s;
    opacity: 1;
  }
  .down {
    top: calc(100vh - 160px);
    opacity: 1;
    transition: $transition 2.6s;
  }
  .fond.fd-about {
    opacity:0;
    transition: $transition;
  }
  .fond.fd-index {
    opacity:0;
    transition: $transition;
  }
  main#folio-helft-index .content {
    transition: opacity $transition 0s;
  }
}

