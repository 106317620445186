//
//  F O R M S
//
//  ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

// Label
// ───────────────────────────────────
label{
	display: block;
	cursor: pointer;
	span{
		display: block;
		margin-bottom: 1rem;
		font-family: $ff-myriadpro;
		font-size: 1.4rem;
		line-height: 1.25;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
}

.field-info{
	display: block;
	margin-top: 1rem;
	margin-bottom: 0;
	font-size: 1.4rem;
	font-style: italic;
}

.form-field{
	margin-bottom: 3rem;
}

.error{
	margin-top: 1rem;
	display: block;
	font-size: 1.4rem;
	line-height: 1.25;
	font-weight: 400;
	color: clr(alert);
}

// Input global
// ───────────────────────────────────
.form-group{
	display: flex;
	flex-wrap: wrap;
	> .form-field{
		&:nth-of-type(odd){
			padding-right: $vr/4;
		}
		&:nth-of-type(even){
			padding-left: $vr/4;
		}
	}
}

@media #{$md}{
	.md-form-group{
		display: flex;
		flex-wrap: wrap;
		> .form-field{
			&:nth-of-type(odd){
				padding-right: $vr/4;
			}
			&:nth-of-type(even){
				padding-left: $vr/4;
			}
		}
	}
}

input, textarea, select, button{
	appearance: none;
}

input[type="submit"], input[type="email"], input[type="password"], input[type="text"], input[type="number"], textarea, select{
	display: block;
	width: 100%;
	padding: 1rem 2rem;
	border-radius: 0;
	background-color: clr(primary);
	border: 1px solid clr(primary);
	font-family: $ff-myriadpro;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
	color: clr(black);
	transition: border-color $anim;

	&:hover, &:focus{
		border-color: clr(black);
	}
}

textarea{
	resize: vertical;
	min-height: 10rem;
}

input[type="submit"]{
  padding: 1.8rem 2.6rem 1.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 2px;
}

.field-icon{
	position: relative;
	input{
		padding-right: 6.6rem;
	}
	span{
		position: absolute;
		top: 0;
		right: 0;
		width: 4.6rem;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
		border: 1px solid clr(black);
		background-color: clr(white);
	}
}

// Input select
// ───────────────────────────────────
select{
	background-color: transparent;
  background-image: svg-inline(chevron-down);
	background-repeat: no-repeat;
	border: 0;
	border-bottom: 1px solid clr(black);
	background-size: 1rem;
  background-position: center right 1rem;
	padding: 0 2rem 0 0;
  cursor: pointer;

  &::-ms-expand{
    display: none;
  }

  &:disabled{
    cursor: default;
  }
}

// Input checkbox & input radio
// ───────────────────────────────────
.custom-checkbox,
.custom-radio{
	input[type="checkbox"],
	input[type="radio"]{
		display: none;
		+ span{
			display: block;
			position: relative;
			padding-left: 2.5rem;
      text-transform: none;
      letter-spacing: 0px;
      font-size: 1.6rem;
			transition: color $anim;
			user-select: none;
			cursor: pointer;
			&:before,
			&::after{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 1.4rem;
				height: 1.4rem;
			}
			&::before{
				border: 1px solid clr(black);
			}
			&::after{
				opacity: 0;
				transition: opacity $anim;
			}
		}

		&:checked{
			+ span{
				&::after{
					opacity: 1;
				}
			}
		}
	}

	+ .error{
		padding-left: 4rem;
	}
}

// Input checkbox
// ───────────────────────────────────
.custom-checkbox{
	input[type="checkbox"]{
		+ span{
			&::after{
				width: 1.6rem;
				height: 1.6rem;
				background-image: svg-inline(close);
				background-repeat: no-repeat;
				background-size: 1.6rem;
				background-position: center center;
			}
		}
	}
}

// Input radio
// ───────────────────────────────────
.custom-radio{
	display: flex;
	align-items: center;
	input[type="radio"]{
		+ span{
			&:before,
			&::after{
				border-radius: 50%;
			}
			&::after{
				width: 0.8rem;
				height: 0.8rem;
				top: 4px;
				left: 4px;
				background-color: clr(black);
			}
		}
	}
}

.grecaptcha-badge { visibility: hidden; }
