.home {
  .loop-projets {
    &.home {
      .bloc-projet{
        &:nth-child(1) {
          position: relative;
          margin-bottom: 200px;
          margin: 0 0 120px;
          width:100%;
          flex: 0 0 100%;
          padding:0;
          a {
            text-decoration: none;
            width: 100%;
          }
          img {
            width: 100%;
            object-fit: cover;
            height: calc(100vh - 330px);
          }
          .caption {
            position: absolute;
            bottom: 120px;
            text-align: center;
            width: 100%;
            color: #FFF;
            h2 {
              text-shadow: 0px 0px 20px black;
            }
            h3 {
              color:#FFF;
              text-shadow: 0px 0px 20px black;
            }
          }
        }
      }
    }
  }
}
.anim-intro {
  width:40px;
  height:40px;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .intro-et {
    width:40px;
    position: relative;
    top:80px;
  }
}

/* -- HOME ANIM --*/
body.home:not(.non-event) {
  .logo {
    width: 570px;
    left: calc(50% - 285px);
    background: $rose-dark;
    .helft-logo{
      top:0;
      transition: $transition;
      transition-delay: 1s;
    }
    .et-logo{
      top:5px;
      transition: $transition;
      transition-delay: 1.1s;
    }
    .pinta-logo {
      top:0;
      transition: $transition;
      transition-delay: 1.2s;
    }

  }
  .cote-gauche {
    .helft {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }
    .separateur {
      right:$col1*5;
      width:$col1*7;
      transition: width $transition 1s, right $transition 1s;
  
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }
  .cote-droit {
    .pinta {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }

    .separateur {
      left:$col1*5;
      width:$col1*7;
      transition: width $transition 1s, left $transition 1s;
  
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }
  .title h1 {
    top: 0;
    transition: top $transition 1.6s;
  }

  .lien-about, .lien-index {
    span {
      position: relative;
      top:0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  }
  .fond.fd-home {
    opacity:1;
    transition: $transition 0s;
  }
  .separator-middle {
    top: calc(100vh - 200px);
    opacity: 1;
    transition: $transition 3s;
  }
  .down {
    top: calc(100vh - 230px);
    opacity: 1;
    transition: $transition 2.6s;
  }
  .fond.fd-about {
    opacity:0;
    transition: $transition;
  }
  .fond.fd-index {
    opacity:0;
    transition: $transition;
  }
  .barre-haut {
    background-color: $rose-dark;
  }
  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }
  footer {
    opacity: 1;
    transition: opacity $transition 0s, background-color $transition 1s;
  }
  #home .content {
    transition: opacity $transition 0s;
  }
}

