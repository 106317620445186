//
//  B U T T O N S
//
//  ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

.button{
  padding: 1.8rem 2rem 1.5rem;
  border: 1px solid clr(black);
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  font-family: $ff-myriadpro;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 2px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: clr(black);
  cursor: pointer;
  transition: background-color $anim,
              color $anim,
              border-color $anim;

  @media #{$md}{
    padding: 1.8rem 2.6rem 1.5rem;
  }

  //  Size
  //  ───────────────────────────────────
  &.small{
    padding: 1.8rem 1.5rem 1.5rem;
  }

  //  Colors
  //  ───────────────────────────────────
  &.primary{
    background-color: clr(primary);
    border-color: clr(primary);
    &:hover,
    &:focus,
    &:active{
      border-color: clr(black);
    }
  }

}

