//
//  H E A D E R
//
//  ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
.grid {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  pointer-events: none;

  .col {
    width: $col1;
    height: 100%;
    border-left: 1px solid magenta;
  }
}

header {
  text-transform: uppercase;
  position: relative;
  z-index: 3;

  a {
    text-decoration: none;
  }


  .barre-haut {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 70px 0 30px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    font-size: 12px;
    letter-spacing: 5px;
    transition: $transition;
    z-index: 1;

    &.scrolled {
      background-color: $rose-dark;
    }

    .cote-gauche {
      display: flex;
      position: relative;
      padding: 0 $col1;
      align-items: center;
      justify-content: flex-start;
      width: $col1*16;
      text-align: center;
      transition: width 0.6s ease;

      .helft,
      .pinta {
        width: $col1*2;
        margin: 0 $col1 0 0;
        overflow: hidden;
        position: relative;
        z-index: 1;

        a {
          position: relative;
          display: block;
          background: transparent !important;
          top: 30px;
          z-index: 1;
        }
      }

      .et {
        width: $col1;
        position: relative;
        z-index: 1;
        overflow: hidden;

        a {
          position: relative;
          display: block;
          padding: 0 $col1/4;
          background: transparent !important;
          top: 40px;
          z-index: 1;
        }

      }

      .separateur {
        right: $col1*5;
      }

      .separateur2 {
        right: $col1*12;
      }

      .separateur3 {
        right: $col1*15;
      }
    }

    .cote-droit {
      display: flex;
      position: relative;
      padding: 0 $col1;
      align-items: center;
      justify-content: flex-end;
      width: $col1*16;
      text-align: center;


      .helft,
      .pinta {
        width: $col1*2;
        margin: 0 0px 0 $col1;
        overflow: hidden;
        position: relative;
        z-index: 1;

        a {
          position: relative;
          display: block;
          background: transparent !important;
          top: 40px;
          z-index: 1;
        }
      }

      .et {
        width: $col1;
        overflow: hidden;
        position: relative;
        z-index: 1;

        a {
          position: relative;
          display: block;
          padding: 0 $col1/4;
          background: transparent !important;
          top: 40px;
          z-index: 1;
        }

      }

      .separateur {
        left: $col1*5;
      }

      .separateur2 {
        left: $col1*12;
      }

      .separateur3 {
        left: $col1*15;
      }
    }

    .logo {
      width: 570px;
      text-align: center;
      overflow: hidden;
      position: absolute;
      height: 100px;
      left: calc(50% - 285px);
      top: 35px;
      z-index: 2;
      background: $rose-light;
      transition: width $transition 1s, left $transition 1s, transform $transition 0s, background-color $transition 0s;

      .helft-logo {
        width: 210px;
        padding: 0 10px 0 10px;
        overflow: hidden;
        position: absolute;
        left: 20px;
        top: 100px;
        max-height: 95px;

        svg {
          position: relative;
        }
      }

      .pinta-logo {
        width: 210px;
        padding: 0 10px 0 10px;
        overflow: hidden;
        position: absolute;
        right: 20px;
        top: 100px;
        max-height: 95px;

        svg {
          position: relative;
        }
      }

      .et-logo {
        width: 85px;
        padding: 0;
        overflow: hidden;
        position: absolute;
        left: calc(50% - 42.5px);
        top: 100px;

        svg {
          position: relative;
        }
      }
    }

    .separateur {
      position: absolute;
      height: 1px;
      width: 0;
      top: calc(50%);
      background: $noir;
      z-index: -1;
    }

    .separateur2 {
      position: absolute;
      height: 1px;
      width: 0;
      top: calc(50%);
      background: $noir;
      z-index: -1;
    }

    .separateur3 {
      position: absolute;
      height: 1px;
      width: 0;
      top: calc(50%);
      background: $noir;
      z-index: -1;
    }
  }
}

.fond {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  opacity: 0;

  &.fd-home {
    left: 0;
    background: $rose-dark;
  }

  &.fd-about {
    top: 0vh;
    background: #ffdacf;
    z-index: -1;
  }

  &.fd-index {
    top: 0vh;
    background: #c5d5c7;
    z-index: -1;
  }
}

.down {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  border: 1px solid;
  top: calc(100vh - 30px);
  left: calc(50% - 15px);
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
  opacity: 0;
  transition: $transition;
}

// .down.scrolled {
//   opacity: 0 !important;
//   transition: opacity $transition 0s !important;

// }
.subnav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  margin: 30px 0 0;
  top: 125px;
  position: absolute;
  z-index: 0;
  max-width: 100%;
  margin: auto;
  width: 100%;
  opacity: 0;
  pointer-events: none !important;

  .subnav-line {
    width: $col1*6;
    opacity: 0;
    text-align: center;
    transition: $transition;

    a {
      text-decoration: none;
      font-size: 16px;
      letter-spacing: 3px;
      position: relative;
      overflow: hidden;
      display: inline-block;
      line-height: 1;
      transition: $transition;
      opacity: .5;
    }
  }

  .sep-active {
    position: absolute;
    height: 0px;
    width: 1px;
    background: $noir;
    bottom: 0;
    left: calc(50%);
    transition: $transition;
  }
}


.ball {
  width: 80px;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 99;

  &.active {
    .circular {
      stroke-width: 0;
      height: 0%;
      width: 0%;
      opacity: 0;
    }
  }

  .circular {
    height: 40%;
    transform-origin: center center;
    width: 40%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    stroke-width: 0.5;
    transition: $transition2;
    opacity: 1;

    .path {
      stroke-dasharray: 200, 200;
      stroke-dashoffset: 0;
      stroke-width: 5;
      stroke-linecap: round;
      stroke: #010103;
      fill: #010103;
      opacity: .05;
      mix-blend-mode: difference;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: -124px;
  }
}

.non-event {
  .circular {
    opacity: 1;
    animation: rotate 2s linear infinite;
    width: 100%;
    height: 100%;

    .path {
      stroke-dasharray: 89, 200;
      stroke-width: 0.5;
      fill: none;
      opacity: 1;
    }
  }
}

.non-event {
  .barre-haut {
    .logo {
      .bg {
        opacity: 0;
        transition: opacity 0.3s ease-out 1.1s, background-color 0s ease 1.5s;
      }
    }
  }
}

.barre-haut {
  .logo {
    .bg {
      height: 200px !important;
    }
  }

  .bg {
    background: $rose-dark;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: $transition2 transform;
    z-index: -2;
  }
}

body:not(.non-event) {
  .barre-haut {
    &.scrolled {
      .bg {
        transform: translate(0, -90px);
      }
    }
  }
}

.logo {
  transition: width $transition 1s, left $transition 1s, transform $transition 0s, background-color $transition 0s;

}

.folio-index,
.press,
.partners,
.folio-helft-item,
.folio-pinta-item,
.folio-item,
.folio-index {
  .logo {
    transform: translate(0, -55px) scale(0.40) !important;
  }

  .barre-haut {
    padding: 15px 0 !important;
  }
}

body:not(.non-event) {
  .scrolled {
    padding: 15px 0;
    transition: $transition2;

    .logo {
      transform: translate(0, -55px) scale(0.40);
      transition: width $transition 1s, left $transition 1s, transform $transition2 0s, background-color $transition 0s;
    }
  }
}

.scroll-version {
  transform: translate(0, -40px) scale(0.7);
}



.scrolled {
  .cote-droit {
    width: 50vw !important;
    transition: width 0.6s ease 0.15s;
  }

  .cote-gauche {
    width: 50vw !important;
    transition: width 0.6s ease 0.15s;
  }
}

.non-event {
  .scrolled {
    .cote-droit {
      width: calc(50vw - 300px) !important;
      transition: width 0.3s ease 0s;
    }

    .cote-gauche {
      width: calc(50vw - 300px) !important;
      transition: width 0.3s ease 0s;
    }
  }
}

.lien-about {
  position: fixed;
  bottom: 100px;
  left: calc(6.25vw - 9px);
  z-index: 1;
  transform: rotate(-90deg);
  transform-origin: left top;
  letter-spacing: 5px;
  font-size: 12px;
  overflow: hidden;

  span {
    position: relative;
    top: -15px;
    transition-delay: 1.9s;
    opacity: 0;

  }
}

.lien-index {
  position: fixed;
  bottom: 95px;
  right: calc(6.25vw - 9px);
  z-index: 1;
  transform: rotate(90deg);
  transform-origin: right top;
  letter-spacing: 5px;
  font-size: 12px;
  overflow: hidden;

  span {
    position: relative;
    top: -15px;
    opacity: 0;
  }
}

a:not(.archive-items) {
  &.lien-retour {
    position: fixed;
    top: 130px;
    right: calc(3.125vw - 40px);
    transform: rotate(90deg);
    z-index: 1;
    transform-origin: left top;
    letter-spacing: 5px;
    font-size: 12px;
    overflow: hidden;
    pointer-events: none;
    transition: $transition;
    transition-delay: 1.9s;

    span {
      position: relative;
      top: -15px;
    }
  }

  &.lien-retour-i {
    position: fixed;
    top: 130px;
    left: calc(3.125vw - 40px);
    transform: rotate(-90deg);
    z-index: 1;
    transform-origin: right top;
    letter-spacing: 5px;
    font-size: 12px;
    overflow: hidden;
    pointer-events: none;
    transition: $transition;
    transition-delay: 1.9s;

    span {
      position: relative;
      top: -15px;
    }
  }

  &.lien-retour-p {
    position: fixed;
    top: 130px;
    left: calc(3.125vw - 40px);
    transform: rotate(-90deg);
    z-index: 1;
    transform-origin: right top;
    letter-spacing: 5px;
    font-size: 12px;
    overflow: hidden;
    pointer-events: none;
    transition: $transition;
    transition-delay: 1.9s;

    span {
      position: relative;
      top: -15px;
    }
  }
  
}

.et svg {
  max-height: 25px;
  max-width: 35px;
}
