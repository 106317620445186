.archive {
  padding: 0px 0px 0;
  margin:0 $col1*2;
  border-top:1px solid $noir;
  position: relative;
  background: #c5d5c7;
  // a, span, p {
  //   color:#F0ECE4;
  //   text-decoration: none;
  //   svg {
  //     fill:#F0ECE4;
  //   }
  // }
  .item {
    padding:10px 0;
    display: flex;
    &:first-child {
      padding-top: 30px;
    }
    &:last-child {
      border-bottom:1px solid $noir;
      padding-bottom: 30px;
    }
    .flexed {
      display: flex;
      text-decoration: none;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
    }
  }
  span.date {
    width: 30px;
  }
  h3 {
    width: $col1*10;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-right: 3rem;
  }
  p.lieu {
    width: $col1*7;
  }
  p.mission {
    width: $col1*6;
  }

  span.icon {
    width: 60px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      height: 16px;
      width:auto;
      margin:auto;
    }
  }
  img {
    display: none;
  }
}
main#index, main#press, main#partners {
  padding-top:250px;
}
.lien-about, .lien-index {
  transition: $transition;
}
/* -- INDEX ANIM --*/
body.folio-index:not(non-event) {
  .logo {
    width: 570px;
    left: calc(50% - 285px);
    background: #c5d5c7;
    
    .helft-logo{
      top:0;
      transition: $transition;
      transition-delay: 1s;
    }
    .et-logo{
      top:5px;
      transition: $transition;
      transition-delay: 1.1s;
    }
    .pinta-logo {
      top:0;
      transition: $transition;
      transition-delay: 1.2s;
    }

  }
  .cote-gauche {
    .helft {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }
    .separateur {
      right:$col1*5;
      width:$col1*7;
      transition: width $transition 1s, right $transition 1s;
  
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }
  .cote-droit {
    .pinta {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }

    .separateur {
      left:$col1*5;
      width:$col1*7;
      transition: width $transition 1s, left $transition 1s;
  
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }
  .title h1 {
    top: 0;
    transition: top $transition 1.6s;
  }

  .lien-about, .lien-index {
    span {
      position: relative;
      top:0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  }
  
  .lien-index {
    color:#999
  }
  .fond.fd-home {
    opacity:0;
    transition: $transition 1s;
  }
  .vertical-separateur {
    top: auto;
    height: 0;
    transition: $transition 3s;
    opacity: 1;
    bottom: -60px;
    z-index: -1;
}
  .down {
    top: calc(100vh);
    opacity: 0;
    transition: $transition 2.6s;
  }
  .fond.fd-about {
    opacity:0;
    transition: $transition;
  }
  .fond.fd-index {
    opacity:1;
    transition: $transition;
  }

  .barre-haut {
    background-color: #c5d5c7;
  }
  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }
  footer {
    transition: opacity $transition 0s, background-color $transition 1s;
  }
  .subnav {
    opacity:1;
    pointer-events: all !important;
    .subnav-line {
      opacity: 1;
      transition: $transition 1.6s;
    }
  }
  .index-link {
    opacity: 1;
  }
  .presse-link {
    opacity: .5;
  }
  .partenaires-link {
    opacity: .5;
  }
  .sep-active {
    height:50px;
    transition: $transition 1.2s;
  }
  #index .content {
    transition: opacity $transition 0s;
  }
}

/* -- PRESS ANIM --*/
body.press:not(.non-event) {
  .logo {
    width: 570px;
    left: calc(50% - 285px);
    background: #c5d5c7;
    
    .helft-logo{
      top:0;
      transition: $transition;
      transition-delay: 1s;
    }
    .et-logo{
      top:5px;
      transition: $transition;
      transition-delay: 1.1s;
    }
    .pinta-logo {
      top:0;
      transition: $transition;
      transition-delay: 1.2s;
    }

  }
  .cote-gauche {
    .helft {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }
    .separateur {
      right:$col1*5;
      width:$col1*7;
      transition: width $transition 1s, right $transition 1s;
  
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }
  .cote-droit {
    .pinta {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }

    .separateur {
      left:$col1*5;
      width:$col1*7;
      transition: width $transition 1s, left $transition 1s;
  
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }
  .title h1 {
    top: 0;
    transition: top $transition 1.6s;
  }

  .lien-about, .lien-index {
    span {
      position: relative;
      top:0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  }
  
  .lien-index {
    color:#999
  }
  .fond.fd-home {
    opacity:0;
    transition: $transition 1s;
  }
  .vertical-separateur {
    top: auto;
    height: 0;
    transition: $transition 3s;
    opacity: 1;
    bottom: -60px;
    z-index: -1;
}
  .down {
    top: calc(100vh);
    opacity: 0;
    transition: $transition 2.6s;
  }
  .fond.fd-about {
    opacity:0;
    transition: $transition;
  }
  .fond.fd-index {
    opacity:1;
    transition: $transition;
  }
  .barre-haut {
    background-color: #c5d5c7;
  }
  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }
  footer {
    transition: opacity $transition 0s, background-color $transition 1s;
  }
  .subnav {
    opacity:1;
    pointer-events: all !important;
    .subnav-line {
      opacity: 1;
      transition: $transition 1.6s;
    }
  }
  .index-link {
    opacity: .5;
  }
  .presse-link {
    opacity: 1;
  }
  .partenaires-link {
    opacity: .5;
  }
  .sep-active {
    height:50px;
    left:31%;
    transition: $transition;
  }
  main#press .content {
    top:0;
    transition: opacity $transition 0s;
  }
}

/* -- PARTNERS ANIM --*/

body.partners:not(.non-event) {
  .logo {
    width: 570px;
    left: calc(50% - 285px);
    background: #c5d5c7;
    .helft-logo{
      top:0;
      transition: $transition;
      transition-delay: 1s;
    }
    .et-logo{
      top:5px;
      transition: $transition;
      transition-delay: 1.1s;
    }
    .pinta-logo {
      top:0;
      transition: $transition;
      transition-delay: 1.2s;
    }

  }
  .cote-gauche {
    .helft {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }
    .separateur {
      right:$col1*5;
      width:$col1*7;
      transition: width $transition 1s, right $transition 1s;
  
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }
  .cote-droit {
    .pinta {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }

    .separateur {
      left:$col1*5;
      width:$col1*7;
      transition: width $transition 1s, left $transition 1s;
  
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }
  .title h1 {
    top: 0;
    transition: top $transition 1.6s;
  }
  .lien-about, .lien-index {
    span {
      position: relative;
      top:0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  } 
  .lien-index {
    color:#999
  }
  .fond.fd-home {
    opacity:0;
    transition: $transition 1s;
  }
  .vertical-separateur {
    top: auto;
    transition: $transition 3s;
    opacity: 1;
    height: 0;
    bottom: -60px;
    z-index: -1;
  }
  .down {
    top: calc(100vh);
    opacity: 0;
    transition: $transition 2.6s;
  }
  .fond.fd-about {
    opacity:0;
    transition: $transition;
  }
  .fond.fd-index {
    opacity:1;
    transition: $transition;
  }
  .barre-haut {
    background-color: #c5d5c7;
  }
  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }
  footer {
    transition: opacity $transition 0s, background-color $transition 1s;
  }
  .subnav {
    opacity:1;
    pointer-events: all !important;
    .subnav-line {
      opacity: 1;
      transition: $transition 1.6s;
    }
  }
  .index-link {
    opacity: 0.5;
  }
  .presse-link {
    opacity: .5;
  }
  .partenaires-link {
    opacity: 1;
  }
  .sep-active {
    height:50px;
    left:68%;
    transition: $transition;
  }
  main#partners .content {
    top:0;
    transition: opacity $transition 0s;
  }
}
/* -- item ANIM --*/
body.folio-item:not(.non-event) {
  .logo {
    width: 570px;
    left: calc(50% - 285px);
    background: $rose-dark;
    .helft-logo{
      top:0;
      transition: $transition;
      transition-delay: 1s;
    }
    .et-logo{
      top:5px;
      transition: $transition;
      transition-delay: 1.1s;
    }
    .pinta-logo {
      top:0;
      transition: $transition;
      transition-delay: 1.2s;
    }

  }
  .cote-gauche {
    .helft {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }
    .separateur {
      right:$col1*5;
      width:$col1*7;
      transition: width $transition 1s, right $transition 1s;
  
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }
  .cote-droit {
    .pinta {
      a {
        top:0px;
        transition: $transition;
        transition-delay: 1.9s;
      }

    }
    .et {
      a {
        top:40px;
        transition: $transition;
        transition-delay: 0.8s;
      }
    }

    .separateur {
      left:$col1*5;
      width:$col1*7;
      transition: width $transition 1s, left $transition 1s;
  
    }
    .separateur2 {
      width: $col1;
      transition: width $transition 1.6s;
    }
    .separateur3 {
      width: $col1;
      transition: width $transition 1.6s;
    }
  }
  .barre-haut {
    background-color: $rose-dark;
  }
  .barre-haut.scrolled {
    transition: opacity $transition 0s, background-color $transition 0s, padding $transition;
  }

  footer {
    transition: opacity $transition 0s, background-color $transition 1s;
  }
  .title h1 {
    top: 0;
    transition: top $transition 1.6s;
  }

  .lien-about, .lien-index {
    span {
      position: relative;
      top:0px;
      opacity: 1;
      transition: opacity $transition 1.9s, top $transition2 0s;
    }
  }
  .fond.fd-home {
    opacity:1;
    transition: $transition 0s;
  }
  .separator-middle {
    top: calc(100vh - 200px);
    opacity: 1;
    transition: $transition 3s;
  }
  .down {
    top: calc(100vh - 230px);
    opacity: 1;
    transition: $transition 2.6s;
  }
  .fond.fd-about {
    opacity:0;
    transition: $transition;
  }
  .fond.fd-index {
    opacity:0;
    transition: $transition;
  }
  #folio-item .content {
    transition: opacity $transition 0s;
  }
  footer#footer {
    opacity: 1;
    padding: 0 30px 90px;
}
}

