//
//  A L E R T S
//
//  ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

.alert{
	margin-top: 0.4rem;
  padding: 1rem;
  font-size: 1.2rem;
  margin-bottom: $vr;
  border: 1px solid clr(black);
  p{
    margin-bottom: 0;
  }
  &.error{
    border-color: clr(alert);
    background-color: rgba(clr(alert), 0.5);
  }
  &.success{
    border-color: clr(success);
    background-color: clr(primary);
  }
  &.warning{
    background-color: rgba(clr(warning), 0.5);
  }
  &.info{
    background-color: rgba(clr(info), 0.5);
  }
}

