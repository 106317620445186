//
//  M O D A L
//
//  ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

.modal{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 100%;
  left: 0;
  display: flex;
  align-items: center;
  z-index: -100;
  background-color: rgba(clr(black), 0);
  transition: background $anim $anim-duration,
              z-index 0s 500ms,
              bottom 0ms $anim-easing $anim-duration;

  .modal--inner{
    position: relative;
    width: 90%;
    max-width: $row-width;
    max-height: calc(100vh - #{$vr*4});
    padding: $vr*2 0 $vr;
    background: clr(white);
    opacity: 0;
    pointer-events: none;
    transform: translateY(-150vh);
    transition: opacity $anim 0ms;

    @media #{$lg}{
      max-width: $row-width/2;
    }
  }
  .modal--scroll{
    overflow-y: auto;
  }
  .modal--close{
    position: absolute;
    display: block;
    top: $vr;
    right: $vr;
    font-size: 2rem;
    line-height: 1;
    border-bottom: none;
    cursor: pointer;
  }

  &:target{
    bottom: 0;
    z-index: 100;
    background-color: rgba(clr(black), 0.8);
    transition: background $anim 0ms,
                z-index 0s 0ms,
                bottom 0ms $anim-easing 0ms;

    .modal--inner{
      transform: translateY(0);
      opacity: 1;
      pointer-events: auto;
      transition: opacity $anim $anim-duration;
    }

  }
  #x {
    display: none;
  }

  &.secondary{
    .modal--inner{
      height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
      margin-left: 0;
      padding-top: $vr*4;
      transform: translateY(0) translateX(-100%);
      transition: opacity $anim 0ms, transform $anim 0ms;
      @media #{$lg}{
        max-width: 60%;
      }
      .modal--scroll{
        opacity: 0;
        transition: opacity $anim $anim-duration;
      }

      form{
        margin-top:$vr;
      }
    }
    &:target{
      .modal--inner{
        transform: translateY(0) translateX(0%);
        transition: opacity $anim $anim-duration, transform $anim-duration*4 $anim-easing $anim-duration;
      }
      .modal--scroll{
        opacity: 1;
        transition: opacity $anim-duration*2 $anim-easing $anim-duration*3;
      }
    }
  }
}

.modal-credits{
  .modal--inner{
    max-width: 50rem;
  }
  .modal-credits--content{
    margin-bottom: $vr;
    figure{
      margin: 0 auto $vr/2;
    }
  }
}
