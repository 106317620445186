//
//  V A R I A B L E S
//
//  ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

//  Colors
//  ───────────────────────────────────
@function clr($key){
  @return map-get($colors, $key);
}

$colors: (
  primary: #EAEDE1,
);

$colors: map-merge((
  alert: #BF5544,
  warning: #B8860B,
  info: #205D86,
  success: #8f9f60,
), $colors);

$colors: map-merge((
  black: #000000,

  gray: #808080,

  white: #ffffff,

), $colors);

//  Fonts
//  ───────────────────────────────────
@font-face {
  font-family: "Baskerville";
  src: url("./fonts/Baskerville.woff2") format("woff2"),
    url("./fonts/Baskerville.woff") format("woff"),
    url("./fonts/Baskerville.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Baskerville";
  src: url("./fonts/Baskerville-Italic.woff2") format("woff2"),
    url("./fonts/Baskerville-Italic.woff") format("woff"),
    url("./fonts/Baskerville-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("./fonts/MyriadPro-Regular.woff2") format("woff2"),
    url("./fonts/MyriadPro-Regular.woff") format("woff"),
    url("./fonts/MyriadPro-Regular.ttf") format("truetype"),
    url("./fonts/MyriadPro-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("./fonts/MyriadPro-Bold.woff2") format("woff2"),
    url("./fonts/MyriadPro-Bold.woff") format("woff"),
    url("./fonts/MyriadPro-Bold.ttf") format("truetype"),
    url("./fonts/MyriadPro-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("./fonts/MyriadPro-Italic.woff2") format("woff2"),
    url("./fonts/MyriadPro-Italic.woff") format("woff"),
    url("./fonts/MyriadPro-Italic.ttf") format("truetype"),
    url("./fonts/MyriadPro-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
}

$ff-baskerville: 'Baskerville', serif;
$ff-myriadpro: 'Myriad Pro', 'Arial', sans-serif;

//  Animations
//  ───────────────────────────────────
$anim-duration: 250ms;
$anim-easing: cubic-bezier(.55,0,.1,1) ;
$anim: $anim-duration $anim-easing;

//  Vertical rythmn
//  ───────────────────────────────────
$vr: 2rem;

//  Grid
//  ───────────────────────────────────

$row-width: 144rem;
$container-width: 100%;
$total-columns: 24;
$column-gutter: 0;
$column-width: 6rem;

$debgrid: (
  horizontal: false,
  maxwidth: $row-width,
  width: $container-width,
  columns: $total-columns,
  gutter: $column-gutter,
  colorColumn: rgba(clr(black), 0.1),
  colorGutter: rgba(clr(black), 0.05),
  vertical: false,
  vertical-size: $vr,
  colorVOdd: rgba(clr(black), 0.1),
  colorVEven: transparent
);

//  Breakpoints
//  ───────────────────────────────────

$sm-breakpoint:  640px;
$md-breakpoint: 1024px;
$lg-breakpoint: 1280px;

$sm-range:   (0, $sm-breakpoint);
$md-range:  ($sm-breakpoint  + 1px, $md-breakpoint);
$lg-range:   ($md-breakpoint + 1px, $lg-breakpoint);

$screen: "only screen";

$sm-up: $screen;
$sm-only: "#{$screen} and (max-width: #{nth($sm-range, 2)})";

$md-up: "#{$screen} and (min-width:#{nth($md-range, 1)})";
$md-only: "#{$screen} and (min-width:#{nth($md-range, 1)}) and (max-width:#{nth($md-range, 2)})";

$lg-up: "#{$screen} and (min-width:#{nth($lg-range, 1)})";
$lg-only: "#{$screen} and (min-width:#{nth($lg-range, 1)}) and (max-width:#{nth($lg-range, 2)})";

$retina: (
  "#{$screen} and (-webkit-min-device-pixel-ratio: 2)",
  "#{$screen} and (min--moz-device-pixel-ratio: 2)",
  "#{$screen} and (-o-min-device-pixel-ratio: 2/1)",
  "#{$screen} and (min-device-pixel-ratio: 2)",
  "#{$screen} and (min-resolution: 192dpi)",
  "#{$screen} and (min-resolution: 2dppx)"
);

// Legacy
$sm: $sm-up;
$md: $md-up;
$lg: $lg-up;

$bps: (
 #{$sm} 'sm',
 #{$md} 'md',
 #{$lg} 'lg'
);
