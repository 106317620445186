//
//  F O O T E R
//
//  ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
// .folio-helft-index,
// .folio-pinta-index {
//   footer {
//     background-color: $rose-light;
//   }
// }
.barre-haut.scrolled {
  background: transparent;
  transition: opacity $transition, background-color $transition;
}
footer {
  text-transform: uppercase;
  text-align: center;
  padding-bottom:60px;

  background-color: transparent;
  opacity: 0;
  transition: opacity $transition, background-color $transition;

  a {
        text-decoration: none;
        color: inherit;
        position: relative;
        overflow: hidden;
        display: inline-block !important;
        vertical-align: middle;
  }

  .totop {
    width: 80px;
    height: 80px;
    border: 1px solid;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: auto;
    span {
      top:0px !important;
      transform:none !important;
    }
    &:hover {
       span {
         top: 0px !important;
         transform:none !important;
       }
    }
  }

  .copyrights {
    font-size: 12px;
    padding-top:40px;
    letter-spacing: 3px;
  }
}
.vertical-separateur {
  position: absolute;
  height:1000vh;
  top: auto;
  width: 1px;
  background: #000;
  left: calc(50% - 0.5px);
  z-index: -1;
  transition: $transition;
  opacity: 0;
  height:0

}
.separator-middle {
  position: absolute;
  top: calc(100vh - 130px);
  bottom: -50px;
  left: 50%;
  width: 1px;
  background: #121111;
}